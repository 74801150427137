import './helpers/rh-sso/keycloak-instance'
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import env from './environment'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'


import './assets/style/index.scss'

import ElementPlus from 'element-plus'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import ELLink from 'components/ELLink.vue'
import VueI18n from 'helpers/i18n'
import { install as VueSSE } from 'vue-sse'

import hasRole from 'directives/hasRole'
import clipboard from 'directives/clipboard'
import proxyAzureImg from 'directives/proxyAzureImg'

import './icons'

// TODO: Find better way to do this
// @ts-ignore
if (process.env.NODE_ENV === 'development') {
  // @ts-ignore
  if (module.hot) {
    // @ts-ignore
    module.hot.accept((e) => {
      // eslint-disable-next-line no-console
      console.error(e)
    })
  }
}

const i18nInstance = new VueI18n({
  fallbackNS: ''
})

const app = createApp(App)

app.use(router, {
  env,
  i18n: i18nInstance
})
app.use(ElementPlus)
app.use(hasRole)
app.use(clipboard)
app.use(proxyAzureImg)
app.use(VueI18n, {
  i18n: i18nInstance
})

// Use VueSSE, including a polyfill for older browsers
app.use(VueSSE, {
  polyfill: true
})

app.component('font-awesome-icon', FontAwesomeIcon)
app.component('el-link', ELLink)

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(`ElIcon${key}`, component)
}

app.mount('#app')
