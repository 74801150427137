<template>
  <el-breadcrumb class="app-breadcrumb" separator-icon="el-icon-arrow-right">
    <transition-group name="breadcrumb">
      <el-breadcrumb-item v-for="(item,index) in levelList" :key="item.path + item.name">
        <span
          v-if="item.redirect === 'noRedirect' || index === levelList.length - 1"
          class="no-redirect"
        >
          {{ template(item.meta.title) }}
        </span>
        <el-link
          v-else
          :underline="false"
          :to="{ name: resolve(item) }"
        >
          {{ template(item.meta.title) }}
        </el-link>
      </el-breadcrumb-item>
    </transition-group>
  </el-breadcrumb>
</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-facing-decorator'
import type { RouteLocationNormalizedLoaded, _RouteRecordBase, RouteLocation } from 'vue-router'
import { RouteNames } from '@/route-names'

@Component({ })
export default class Breadcrumb extends Vue {
  levelList: any = null
  timeout: number | null = null

  @Watch('$route', { deep: true })
  onUrlChange (route: RouteLocationNormalizedLoaded) {
    // if you go to the redirect page, do not update the breadcrumbs
    if (route.path.startsWith('/redirect/')) {
      return
    }

    if (this.timeout) {
      clearTimeout(this.timeout)
    }
    this.timeout = setTimeout(() => {
      this.getBreadcrumb()
    }, 100)
  }

  created () {
    this.getBreadcrumb()
  }

  getBreadcrumb () {
    // only show routes with meta.title
    const matched: (_RouteRecordBase | RouteLocation)[] = [
      {
        name: RouteNames.Root,
        path: '/',
        meta: {
          title: 'BE GEMS Portal'
        }
      }
    ]

    const routeNodes = this.$route.matched.filter(item => item.meta && item.meta.title)
    for (const route of routeNodes) {
      matched.push(route)
    }

    if (this.$route.meta && this.$route.meta.menuItem) {
      matched.splice(matched.length - 1, 0, this.$router.resolve({ name: this.$route.meta.menuItem }))
    }

    if (this.$route.meta && this.$route.meta.parent) {
      matched.splice(matched.length - 1, 0, this.$router.resolve({ name: this.$route.meta.parent as string }))
    }

    this.levelList = matched
      .filter(item => item.meta && item.meta.title)
  }

  resolve (item: _RouteRecordBase) {
    return item.redirect && typeof item.redirect === 'object' && 'name' in item.redirect
      ? item.redirect.name
      : item.name
  }

  template (text) {
    const data = this.$route.params
    return text.replace(/{(\w*)}/g, (m, key) => data.hasOwnProperty(key) ? data[key] : '')
  }
}
</script>
