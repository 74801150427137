import type { App } from 'vue'
import type { InitOptions } from 'i18next'
import type { LocalizedTranslations, TranslationMapping } from './types'
import type { Language } from 'enum/Language'
import type VueI18n from 'helpers/i18n/index'

function debugKey (key: string | string[], options: any = null) {
  const keyText = key instanceof Array ? key.join(' | ') : key
  let optionsText = ''
  // options are only displayed for keys that are not related to validation (there, options are well known)
  if (options && keyText.indexOf('.validation.') === -1) {
    // in debug mode interpolation config pollutes the output
    options.interpolation = undefined
    optionsText = options ? ' * ' + JSON.stringify(options) : ''
  }
  return `${keyText}${optionsText}`
}

export default function install (app: App, pluginOptions: { missingKeyHandler?: Function, i18n: VueI18n }): void {
  app.mixin({
    data () {
      return {
        $t_debugEnabled: pluginOptions.i18n ? Boolean(pluginOptions.i18n.debug) : false,
        $t_lang: pluginOptions.i18n ? pluginOptions.i18n.lng() : false
      }
    },
    computed: {
      $i18n () {
        return pluginOptions.i18n
      },
      // the $t function is a computed property to get around cached computed properties that are using $t
      $t (): (key: string | string[], options?: InitOptions) => string {
        if (!this.$data.$t_debugEnabled && this.$data.$t_lang) {
          return function (this: App, key: string | string[], options: InitOptions = {}): string {
            if (!key) return key
            // i18next escape is always disabled as escaping is already managed by vue

            if (pluginOptions.i18n.exists(key)) {
              options.interpolation = { escapeValue: false }
              return pluginOptions.i18n.t(key, options)
            }

            if (pluginOptions && pluginOptions.missingKeyHandler) {
              pluginOptions.missingKeyHandler(pluginOptions.i18n.lng(), pluginOptions.i18n.ns(), debugKey(key), debugKey(key, options))
            }
            return key instanceof Array ? key[0] : key
          }
        } else {
          return function (key: string | string[], options?: InitOptions) {
            return debugKey(key, options)
          }
        }
      },

      /**
       * Checks if a key exists in the loaded translations.
       */
      $t_exists () {
        if (!this.$data.$t_debugEnabled && this.$data.$t_lang) {
          return function (this: App, key: string | string[], options?: InitOptions) {
            if (!key) return false
            return pluginOptions.i18n.exists(key, options)
          }
        } else {
          return function () {
            return true
          }
        }
      }
    },
    created () {
      this.$data.$t_debugEnabled = pluginOptions.i18n ? Boolean(pluginOptions.i18n.debug) : false
      this.$data.$t_lang = pluginOptions.i18n ? pluginOptions.i18n.lng() : false
      pluginOptions.i18n.i18next.on('languageChanged', this.$__onLanguageChange)
    },
    destroyed() {
      pluginOptions.i18n.i18next.off('languageChanged', this.$__onLanguageChange)
    },
    methods: {
      $__onLanguageChange (lng: string): void {
        this.$data.$t_lang = lng
      },
      async $t_setLanguage (lang: Language) {
        await pluginOptions.i18n.setLanguage(lang)
      },
      /**
       * Load additional translation files
       */
      $t_load (this: App, files: LocalizedTranslations) {
        return pluginOptions.i18n.load(files)
      },

      /**
       * Given a key in list format, collects all items and returns it as an array.
       * e.g.: translate.key.prefix.0 = "a"
       *       translate.key.prefix.1 = "b"
       *       $t_list('translate.key.prefix')  =>  ["a", "b"]
       */
      $t_list (key: string, options?: InitOptions) {
        const ret: string[] = []
        let index = 0
        while (pluginOptions.i18n.exists(`${key}.${index}`)) { ret.push(this.$t(`${key}.${index++}`, options)) }
        return ret
      },

      /**
       * Given a mapping, returns a list of objects that correspond to this mapping.
       * e.g.: translate.key.label.0 = "label 1"
       *       translate.key.description.0 = "description 1"
       *       translate.key.label.1 = "label 2"
       *       translate.key.description.1 = "description 2"
       *       $t_map({ label: 'translate.key.label', description: 'translate.key.description' })
       *          =>  [{ label: "label 1", description: "description 1" }, { label: "label 2", description: "description 2" }]
       */
      $t_map (mapping: TranslationMapping, options?: InitOptions) {
        const collect = (from = 0, into: (string | number)[] = []) => {
          const collection = { ...mapping }
          let stop = true

          for (const [key, value] of Object.entries(mapping)) {
            const exists = pluginOptions.i18n.exists(`${value}.${from}`)
            collection[key] = exists ? this.$t(`${value}.${from}`, options) : null
            stop = stop && !exists
          }

          // @ts-ignore
          return stop ? into : collect(from + 1, into.concat([collection]))
        }

        return collect()
      },

      /**
       * Converts a given translation key to a boolean. If the translated value corresponds to
       * 'false' or 'no', returns false. Otherwise, returns true.
       */
      $t_bool (key: string | string[], options?: InitOptions): boolean {
        const falsey = ['false', 'no'] // All strings that are considered false
        const casesensitive = false // Flag that indicated whether we care about case
        const whitespaced = true // Flag whether leading and/or trailing whitespace is allowed

        const regex = new RegExp(`^(?:${falsey.join('|')})$`, `g${casesensitive ? '' : 'i'}`)
        const translation = pluginOptions.i18n.t(key, options)
        return !(whitespaced ? translation.trim() : translation).match(regex)
      },

      /**
       * Retrieve enum values for selected enum for dropdown input
       */
      $t_dropdown (enumClass: any, prefixes: string | string[], filtered: any = []) {
        if (typeof prefixes === 'string') { prefixes = [prefixes] }
        const translatedEnumValues: { key: string, value: any, text: string }[] = []

        const enumNames = Object.values<string>(enumClass)
          .filter((name) => filtered.indexOf(name) === -1)

        for (const elm of enumNames) {
          const keys: string[] = []
          for (const prefix of prefixes) {
            keys.push(`${prefix}.${elm}`)
          }
          translatedEnumValues.push({
            key: elm,
            value: elm,
            // @ts-ignore
            label: this.$t(keys)
          })
        }
        return translatedEnumValues
      },

      /**
       * Retrieve enum values for selected enumClass for dropdown input
       * @param {Object} enumClass
       * @param {Array|String} prefixes
       * @param {Array} filtered
       * @return {Array}
       */
      $t_enum (enumClass: any, prefixes: string | string[], filtered: any = []) {
        if (typeof prefixes === 'string') { prefixes = [prefixes] }
        const translatedEnumValues: { value: any, text: string }[] = []

        const enumNames = Object.entries(enumClass)
          .filter(([ name ]) => filtered.indexOf(name) === -1)
          .map(([ name ]) => name)

        for (const elm of enumNames) {
          const keys: string[] = []
          for (const prefix of prefixes) {
            keys.push(`${prefix}.${elm}`)
          }
          translatedEnumValues.push({
            value: elm,
            text: this.$t(keys)
          })
        }
        return translatedEnumValues
      },

      /**
       * Switches the i18n debug mode.
       * @return {Boolean}
       */
      $t_switchDebugMode () {
        pluginOptions.i18n.debug = !pluginOptions.i18n.debug
        return pluginOptions.i18n.debug
      }
    }
  })
}
