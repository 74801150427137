<template>
  <router-link
    v-if="to"
    :class="[
      'el-link',
      type ? `el-link--${type}` : '',
      disabled && 'is-disabled',
      underline && !disabled && 'is-underline'
    ]"
    :to="to"
    v-bind="$attrs"
  >
    <el-icon v-if="icon">
      <component :is="icon" />
    </el-icon>
    <span v-if="$slots.default" class="el-link__inner">
      <slot></slot>
    </span>
    <template v-if="$slots.icon">
      <slot v-if="$slots.icon" name="icon"></slot>
    </template>
  </router-link>
  <a
    v-else
    :class="[
      'el-link',
      type ? `el-link--${type}` : '',
      disabled && 'is-disabled',
      underline && !disabled && 'is-underline'
    ]"
    :href="href"
    v-bind="$attrs"
    @click="handleClick"
  >
    <el-icon v-if="icon">
      <component :is="icon" />
    </el-icon>
    <span v-if="$slots.default" class="el-link__inner">
      <slot></slot>
    </span>
    <template v-if="$slots.icon">
      <slot v-if="$slots.icon" name="icon"></slot>
    </template>
  </a>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-facing-decorator'

@Component({
  emits: ['click']
})
export default class ELLink extends Vue {
  @Prop({ type: [String, Object] })
  readonly to!: string | object | undefined

  @Prop({ type: String, default: 'default' })
  readonly type!: string

  @Prop({ type: Boolean, default: true })
  readonly underline!: boolean

  @Prop({ type: Boolean, default: false })
  readonly disabled!: boolean

  @Prop({ type: String })
  readonly icon!: string

  @Prop({ type: String })
  readonly href!: string

  handleClick (event) {
    if (!this.disabled && !this.href) {
      this.$emit('click', event)
    }
  }
}
</script>
