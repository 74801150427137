import {getWorkItemAttachmentById} from 'service/itsm/itsm';
import { App, DirectiveBinding } from 'vue';

export default function install (app: App): void {
  function loadImageThroughProxy(imgElement: HTMLImageElement, workItemId: number, attachmentId: string): void {
    getWorkItemAttachmentById(workItemId, attachmentId)
      .then(response => {
        const url = URL.createObjectURL(response.data);
        imgElement.src = url;
      })
      .catch(error => {
        // eslint-disable-next-line no-console
        console.error('Failed to load image through proxy server !')
      });
  }

  function extractAzureAttachmentId(url: string) {
    const azureDevOpsPattern = /^https?:\/\/dev\.azure\.com\/.*\/_apis\/wit\/attachments\/([^/?]+)/;
    const match = url.match(azureDevOpsPattern)
    if (match && match[1]) {
      return match[1]
    }
    return null;
  }

  app.directive('proxy-azure-img', {
    mounted (el: HTMLElement, binding: DirectiveBinding) {
      el.querySelectorAll('img').forEach((imgElement: HTMLImageElement) => {
        const src = imgElement.getAttribute('src');
        if (src && src.includes('dev.azure.com')) {
          const workItemId = binding.value
          const attachmentId = extractAzureAttachmentId(src)
          if (workItemId && attachmentId) {
            imgElement.onerror = () => loadImageThroughProxy(imgElement as HTMLImageElement, workItemId, attachmentId);
          }
        }
      });
    }
  });
}
