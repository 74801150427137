/* eslint-disable camelcase */

import install from './install'
import i18next, { i18n, InitOptions } from 'i18next'
import {
  LocalizedTranslations,
  NamespaceTranslations,
  Translations
} from './types'
import { Language } from 'enum/Language'

export const NAMESPACE_SEPARATOR = '#'
export const DEFAULT_NAMESPACE = 'default'
export const I18N_LANGUAGE_KEY = 'language'

export default class VueI18n {
  public debug: boolean = false
  readonly i18next: i18n
  readonly defaultNS: string

  constructor ({
    debug = false,
    defaultNS = DEFAULT_NAMESPACE,
    keySeparator = false,
    fallbackNS = false
  }: InitOptions = {}) {
    this.debug = debug
    this.defaultNS = defaultNS

    this.debug = debug
    this.defaultNS = defaultNS

    const lng = this.getLanguage()

    // @ts-ignore
    this.i18next = i18next
      .createInstance({
        ns: ['common', 'menu'],
        debug,
        lng,
        keySeparator,
        defaultNS,
        fallbackNS,
        fallbackLng: Language.EN
      })
      .use({
        type: 'backend',
        init (services, backendOptions, i18nextOptions) { /* use services and options */ },
        read (language, namespace, callback) {
          if (language === 'dev') {
            callback(null, {})
            return
          }
          import(`locales/${language.toLowerCase()}/${namespace}.json`)
            .then((resources) => {
              callback(null, resources)
            })
            .catch((error) => {
              // eslint-disable-next-line no-console
              console.info(`loading locales for '${language.toLowerCase()}/${namespace}' aborted`)
              callback(error, null)
            })
        }
      })

    this.i18next.init()
    this.i18next.changeLanguage(lng) // DO NOT REMOVE OR YOU WILL DESTROY THE WORLD
  }

  getLanguage (): Language {
    try {
      const item = window.localStorage.getItem(I18N_LANGUAGE_KEY)
      if (item) {
        return JSON.parse(item)
      }
    } catch {}
    return Language.EN
  }

  async setLanguage (language: Language): Promise<void> {
    window.localStorage.setItem(I18N_LANGUAGE_KEY, JSON.stringify(language))
    await this.i18next.changeLanguage(language)
  }

  public t (key: string | string[], options?: any) {
    return this.i18next.t(key, options)
  }

  public get (key: string | string[], options?: any) {
    return this.i18next.t(key, options)
  }

  public exists (key: string | string[], options?: any): boolean {
    return this.i18next.exists(key, options)
  }

  public lng () {
    return this.i18next.language
  }

  public ns () {
    return this.i18next.options.defaultNS || DEFAULT_NAMESPACE
  }

  /**
   * Loads extra translation files in an already configured i18next instance.
   * The TermTranslations service is used to retrieve the translation data.
   * i18next should have been initialized first.
   * @param data the translation files to load in order to enrich i18next
   * @return i18next with the extra translations
   */
  public load (data: LocalizedTranslations): i18n {
    const lng = this.i18next.options.lng || 'en'
    const translationsByNamespace = this.getTranslationsByNamespace(data[lng])
    for (const ns in translationsByNamespace) {
      this.i18next.addResourceBundle(lng, ns, translationsByNamespace[ns], true, true)
    }
    return this.i18next
  }

  public loadNamespaces (ns: string | string[]) {
    return this.i18next.loadNamespaces(ns)
  }

  protected getTranslationsByNamespace (translations: Translations): NamespaceTranslations {
    const nameSpacedValues: NamespaceTranslations = { [DEFAULT_NAMESPACE]: {} }
    for (const key in translations) {
      const split = key.split(NAMESPACE_SEPARATOR)
      if (split.length === 2) {
        const ns = split[0]
        if (!nameSpacedValues[ns]) {
          nameSpacedValues[ns] = {}
        }
        nameSpacedValues[ns][split[1]] = translations[key]
      } else {
        nameSpacedValues[DEFAULT_NAMESPACE][key] = translations[key]
      }
    }
    return nameSpacedValues
  }

  static install = install
}
