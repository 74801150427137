/* eslint-disable @typescript-eslint/no-var-requires */
import type { Environment } from '@/environment'
import type { App } from '@vue/runtime-core'
import type VueI18n from 'helpers/i18n'

import { createRouter, createWebHistory } from 'vue-router'
import { RouteNames } from '@/route-names'

export default function install(app: App, pluginOptions: { env: Environment, i18n: VueI18n }): void {
  const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes: process.env.VUE_APP_MODE !== 'public' ? [
      ...require('@/routers/root-route').rootRoute,
      ...require('@/routers/profile-route').profileRoute,
      ...require('@/routers/admin-route').adminRoute,
      ...require('@/routers/batches-route').batchesRoute,
      ...require('@/routers/messaging-configs-route').messagingConfigsRoute,
      ...require('@/routers/deeplinks-route').deeplinksRoute,
      ...require('@/routers/users').users,
      ...require('@/routers/b2b-impersonate-route').b2bImpersonateRoute,
      ...require('@/routers/target-alert-route').targetAlertRoute,
      ...require('@/routers/prices-route').pricesRoute,
      ...require('@/routers/monitoring-route').monitoringRoute,
      ...require('@/routers/tickets').ticketsRoute,
    ] : [
      ...require('@/routers/root-route').rootRoute,
      ...require('@/routers/tickets').ticketsRoute,
    ]
  })

  router.beforeEach(async (to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth) && !(await window.auth.isAuthenticated())) {
      await window.auth.login(to.fullPath)
    } else if (to.meta.allowInProd === false && pluginOptions.env.get('WEBSITE_ENV') === 'PROD') {
      await router.push({
        name: RouteNames.ProfileRequestAccess,
        query: {roles: `${to.meta.roles}`}
      })
    } else if (!to.meta.roles || await window.auth.hasRoles(to.meta.roles)) {
      if (to.name && typeof to.name === 'string') {
        await pluginOptions.i18n.loadNamespaces(to.name)
        if (to.meta.additionalTranslations) {
          await pluginOptions.i18n.loadNamespaces(to.meta.additionalTranslations)
        }
      }
      next()
    } else {
      await router.push({
        name: RouteNames.ProfileRequestAccess,
        query: {roles: `${to.meta.roles}`}
      })
    }
  })

  app.use(router)
}
